<template>
<q-page class="flex flex-center">
  <p class="text-faded">Notifications</p>
</q-page>
</template>

<script>
export default {
  name: 'PageNotifications'
}
</script>
